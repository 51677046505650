import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    playing: false
  },
  mutations: {
    play(state){
      state.playing = true
      // var r = document.querySelector(':root');
      // r.style.setProperty('--white', 'black');
      // r.style.setProperty('--black', 'white');
      document.documentElement.style.setProperty("--white", "black");
      document.documentElement.style.setProperty("--black", "white");
    },
    pause(state){
      state.playing = false
      document.documentElement.style.setProperty("--black", "black");
      document.documentElement.style.setProperty("--white", "white");
      // var r = document.querySelector(':root');
      // r.style.setProperty('--white', 'black');
      // r.style.setProperty('--black', 'white');
    }
  },
  actions: {},
  modules: {},
});
