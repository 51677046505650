<template>
  <div id="app">
    <div class="menuTips" @click="test" :class="{ alt: black && !open }"></div>
    <menuu v-if="open" />
    <router-view />
    <foo v-if="$route.path !== '/about'"/>
  </div>
</template>

<script>
import Menuu from "./components/menu.vue";
import Foo from "./components/Foo.vue";
export default {
  components: {
    Menuu,
    Foo,
  },
  data() {
    return {
      open: false,
      black: false,
    };
  },
  methods: {
    test() {
      this.open = !this.open;
    },
  },
  mounted() {
    const reg = new RegExp("/works/+.+$");
    if (reg.test(this.$route.path)) this.black = true;
    else this.black = false;
    const works = new RegExp("/works/+.+$");
    if (works.test(this.$route.path)) document.body.style.background = "white";
    else if (this.$route.path === "/about")
      document.body.style.background = "#db3832";
    else document.body.style.background = "black";
  },
  watch: {
    $route() {
      const works = new RegExp("/works/+.+$");
      if (works.test(this.$route.path)) {
        document.body.style.background = "white";
        this.black = true;
      } else if (this.$route.path === "/about") {
        document.body.style.background = "#db3832";
        this.black = true;
      } else {
        document.body.style.background = "black";
        this.black = false;
      }
    },
  },
};
</script>

<style lang="scss">
$red: #db3832;
html{
  --black: black;
  --white: white;
}

*,
*:before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Neue Haas";
  src: url("./assets/font/NeueHaasDisplayMediu.ttf") format("ttf"),
    url("./assets/font/NeueHaasDisplay-Mediu.woff") format("woff"),
    url("./assets/font/NeueHaasDisplay-Mediu.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

// @font-face {
//   font-family: "Neue Haas";

//   src: url("./assets/font/NeueHaasDisplayBold.ttf") format("ttf"),
//   url("./assets/font/NeueHaasDisplay-Bold.woff") format("woff"),
//   url("./assets/font/NeueHaasDisplay-Bold.woff2") format("woff2"),;
//   font-weight: bold;
//   font-style: normal;
//   font-display: fallback;
// }

@font-face {
  font-family: "Neue Haas";
  src: url("./assets/font/NeueHaasDisplay-Roman.ttf") format("ttf"),
    url("./assets/font/NeueHaasDisplay-Roman.woff2") format("woff"),
    url("./assets/font/NeueHaasDisplay-Roman.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Nukle";
  src: url("./assets/font/Nuckle-Medium.ttf") format("ttf"),
    url("./assets/font/Nuckle-Medium.woff") format("woff"),
    url("./assets/font/Nuckle-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Nukle";
  src: url("./assets/font/Nuckle-Semibold.ttf") format("ttf"),
    url("./assets/font/Nuckle-Semibold.woff") format("woff"),
    url("./assets/font/Nuckle-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.menuTips {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 100%;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 400;
  animation: tipsLoop 800ms ease-in-out infinite alternate-reverse;
  transition: 200ms ease-in-out;

  @media screen and (min-width:768px) {
      &:hover {
    background: #db3832;
    animation: none;
    cursor: pointer;
    
  }
  right: 40px;
  top: 40px;
  }

}

@keyframes tipsLoop {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
  }
}

.alt {
  background: black;
}
</style>
