<template>
  <div class="singleImg__container">
    <prismic-image
      :field="slice.primary.image_full"
      v-if="slice.primary.image_full"
    />
    <div class="dida__container" v-if="slice.items[0]">
      <div></div>
      <div class="dida" v-if="slice.items[0].image_dida[0]">
        {{ slice.items[0].image_dida[0].text }}
      </div>
      <div v-if="slice.items[0].ph_cred[0]">
        {{ slice.items[0].ph_cred[0].text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageFull",
  data() {
    return {};
  },
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>

<style lang="scss" scoped>
.singleImg__container {
  margin: auto;
  margin-top: 200px;
  width: clamp(150px, 90%, 1440px);

  img {
    width: 100%;
    height: auto;
  }
}

.dida__container {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: flex-end;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;
  text-align: right;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}
.dida {
  text-align: center;
}
</style>
