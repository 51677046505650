<template>
  <div class="workList__container">
    <router-link :to="'/works/' + id">
      <div class="worklist__textContainer">
        <h2 class="workList__title">{{ title }}</h2>
        <p class="workList__intro">{{ intro }}, {{ year }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    intro: String,
    year: Number,
    id: String,
  },
};
</script>

<style lang="scss" scoped>
.workList__container {
  width: 100%;
  //   border-bottom: 1px solid white;
  border-top: 1px solid white;
  color: white;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  a {
    color: white;
    text-decoration: none;
  }
  &:hover {
    border-top: 1px solid #db3832;
  }
}

.worklist__textContainer {
  display: flex;
  width: 100%;
  padding: 40px 25px;
  margin: auto;
  align-items: flex-start;
  background: black;

  &:hover {
    background: #db3832;
    color: black;
  }
}
.workList__container:hover + .workList__container {
  border-top: 1px solid #db3832;
}
.workList__title {
  margin: 0;
  width: 50%;
  font-size: 100px;
  line-height: 90%;
  text-align: right;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: normal;
  margin-right: 15px;

  @media screen and (max-width:768px) {
    font-size: 7vw;
  }
}

.workList__intro {
  margin: 0;
  width: 25%;
  font-size: 18px;
  line-height: 139%;
  letter-spacing: 0.01em;
  text-transform: lowercase;
  margin-left: 15px;
    @media screen and (max-width:768px) {
    font-size: 16px;
    width: 50%;
  }
}
</style>
