<template>
  <div class="image__container">
    <div class="block__container">
    <tiny-slider v-bind="tinySliderOptions">
      <div v-for="(img, index) in slice.items" :key="'img:'+index">
         <prismic-image :field="img.slide"/>
        
      </div>
      <!-- <div>#1</div>
      <div>#2</div>
      <div>#3</div>
      <div>#4</div>
      <div>#5</div>
      <div>#6</div> -->
    </tiny-slider>
      <!-- <prismic-image :field="slice.primary.image" v-if="slice.primary.image"/>
      <div class="dida__container" v-if="slice.items[0]">
        <div v-if="slice.items[0].ph_cred[0]">{{ slice.items[0].ph_cred[0].text }}</div>
        <div v-if="slice.items[0].image_dida[0]" class="dida">{{ slice.items[0].image_dida[0].text }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider';


export default {
  name: "Slider",
  components: {'tiny-slider': VueTinySlider},
  props: {
    slice: Object,
  },
  data() {
    return {
              tinySliderOptions: {
          mouseDrag: true,
          loop: false,
          controlsPosition: "bottom",
          items: 1,
          gutter: 20,
          swipeAngle: 45,
          nav: false,
          startIndex: 1,
        }
    }
  },
  mounted() {
    console.log(this.slice);
  },
};
</script>
<style lang="scss" scoped>
@import 'tiny-slider/src/tiny-slider';

.image__container {
  margin: auto;
  margin-top: 200px;
  width: clamp(150px, 90%, 1440px);
  display: flex;
  height: auto;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
  overflow: hidden;
}

.block__container {
  width: 66.66%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.dida__container {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;

  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;

  letter-spacing: 0.01em;
  text-transform: uppercase;
  div {
    max-width: 40%;
  }
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}

.right {
  justify-content: right;
}

.dida {
  text-align: right;
}


</style>
