<template>
  <div v-if="loading">
    <h1>DAIDAIDAI</h1>
    <h3>{{ document.data.title[0].text }}</h3>
    <!-- 
    Add the SliceZone to your template, and pass the Slice
    data and a resolver function as props.
  -->
    <SliceZone
      :slices="document.data.body"
      :resolver="({ sliceName }) => slices[sliceName]"
    />
  </div>
</template>

<script>
// Import the SliceZone
import SliceZone from "vue-slicezone";
// Import your Slices (ensure the path is correct)
import TextSlice from "../components/slices/TextSlice";

export default {
  components: {
    SliceZone,
  },
  data() {
    return {
      document: null,
      loading: false,
      // Add your Slices to a "slices" object, which
      // will be used in the resolver function.
      slices: {
        TextSlice,
      },
    };
  },
  methods: {
    // Define a method to query Prismic for your document.
    async getContent() {
      // Query the API and assign the response to "response"
      this.document = await this.$prismic.client.getByUID("work", "ciacco");
      this.loading = true;
    },
  },
  created() {
    // Call your query method when the component loads.
    this.getContent();
  },
};
</script>
