<template>
  <div class="work-page">
    <div class="header">
      <a href="/"
        >VALENTINA <br />
        FURIAN</a
      >
    </div>
    <div class="work__container" v-if="loading">
      <h1 class="work__title">{{ document.data.title[0].text }}</h1>
      <h3 class="work__dida">
        {{ new Date(document.data.date_creation).getFullYear() }},
        {{ document.data.dida[0].text }}
      </h3>
      <prismic-rich-text class="work__intro" :field="document.data.testo" />
      <SliceZone
        v-if="loading"
        :slices="document.data.body"
        :resolver="({ sliceName }) => slices[sliceName]"
      />
    </div>
    <div class="navPrj__container" v-if="loading">
      <router-link v-if="index > 0" :to="`/works/${works[index - 1].uid}`">{{
        works[index - 1].data.title[0].text 
      }}</router-link>
      <router-link to="/works">ALL WORKS</router-link>
      <router-link v-if="index < works.length -1" :to="`/works/${works[index + 1].uid}`">{{
        works[index + 1].data.title[0].text
      }}</router-link>
    </div>
    <div class="selected__container" v-if="loading">
      <router-link
        :to="`/works/${works[i].slugs}`"
        v-for="(n, i) in works"
        :key="i"
        >{{ works[i].data.title[0].text
        }}<span v-if="i < works.length - 1">, </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import SliceZone from "vue-slicezone";

import WorkVideoVimeo from "../components/slices/WorkVideoVimeo.vue";
import PressRelease from "../components/slices/PressRelease.vue";
import ImageFull from "../components/slices/ImageFull.vue";
import ImageFullNew from "../components/slices/ImageFullNew.vue";
import SingleImage2by3 from "../components/slices/SingleImage2by3.vue";
import ImageCenter from "../components/slices/ImageCenter.vue";
import DoubleImage from "../components/slices/DoubleImage.vue";
import ImageDoubleFull from "../components/slices/ImageDoubleFull.vue";
import Credits from "../components/slices/Credits.vue";
import TextCenter from "../components/slices/TextCenter.vue";
import TextLeft from "../components/slices/TextLeft.vue";
import TextRight from "../components/slices/TextRight.vue";
import Slider from "../components/slices/Slider.vue";

export default {
  components: {
    SliceZone,
  },
  data() {
    return {
      document: null,
      loading: false,
      slices: {
        WorkVideoVimeo,
        PressRelease,
        ImageFull,
        ImageFullNew,
        SingleImage2by3,
        ImageCenter,
        DoubleImage,
        ImageDoubleFull,
        Credits,
        TextCenter,
        TextLeft,
        TextRight,
        Slider,
      },
      works: null,
      index: null,
      workslist: null,
    };
  },
  // watch:{
  //   playing(){
  //     // return this.$store.state.playing
  //     if(this.$store.state.playing) {
  //       document.documentElement.style.setProperty("--black", "white");
  //       document.documentElement.style.setProperty("--white", "black");
  //       console.log("HHOHOHHOHOHOHOH")
  //     } else{
  //       document.documentElement.style.setProperty("--black", "black");
  //       document.documentElement.style.setProperty("--white", "white");
  //       console.log("HHOHOHHOHOHOHOH")
  //     }
  //   }
  // },
  methods: {
    getIndex() {
      for (var i = 0; i < this.works.length; i++) {
        if (this.$route.params.id === this.works[i].uid) {
          this.index = i;
          break;
        }
      }
      this.works.slice(this.index, 1)
    },
    // Define a method to query Prismic for your document.
    async getContent(uid) {
      // Query the API and assign the response to "response"
      this.document = await this.$prismic.client.getByUID("work", uid);

      // console.log(this.document);
      //   this.getIndex()
    },
    async getWorks() {
      var wrks = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "work"),
        { orderings: "[my.work.date_creation desc]" }
      );
      this.works = wrks.results;
    },

    async callAll(uid) {
      const uno = this.getContent(uid);
      const due = this.getWorks();
      await Promise.all([uno, due]).then(() => {
        // console.log(this.works);
        // console.log(this.document);
        this.loading = true;
        this.getIndex();
      });
      //   await Promise.all([
      //     (this.document = await this.$prismic.client.getByUID(
      //       "work",
      //       this.$route.params.id
      //     )),
      //     (this.works = await this.$prismic.client.query(
      //       this.$prismic.Predicates.at("document.type", "work"),
      //       { orderings: "[my.work.date_creation desc]" }
      //     )),
      //   ]).then(console.log("CIAO"));
    },
  },
  created() {
    // Call your query method when the component loads.
    // this.getWorks();
    // this.getContent();
    this.callAll(this.$route.params.id);
  },
  mounted() {
    //   this.getIndex();
  },
  beforeRouteUpdate(to, from, next) {
    this.callAll(to.params.id);
    this.$store.commit('pause')
    next();
  },
};
</script>

<style lang="scss">
.work-page {
  min-height: 100vh;
  padding-bottom: 150px;
  background: var(--white);
  color: var(--black);
  transition: 300ms ease-out;

  a {
    text-decoration: none;
    color: var(--black);
  }
}
.header {
  position: sticky;
  mix-blend-mode: difference;
  width: 200px;
  display: block;
  margin: auto;
  // mix-blend-mode: invert;
  z-index: 200;
  top: 0;
  padding-top: 20px;
  color: var(--white);
  font-size: 30px;
  text-align: center;
  letter-spacing: 1;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;
  a {
    color: white;
  }
}
.work__title {
  max-width: 80%;
  margin: auto;
  text-align: center;
  font-size: 100px;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-top: 120px;
  @media screen and (max-width: 768px) {
    font-size: 10vw;
  }
}
.work__dida {
  max-width: 80%;
  margin: auto;
  font-size: 100px;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height, or 130% */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-top: 0;
}

.work__intro {
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  text-align: center;
  width: clamp(200px, 80%, 800px);
  margin: auto;
  margin-top: 68px;
  letter-spacing: 0.01em;

  margin-bottom: 120px;
}

.navPrj__container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 200px;
  width: clamp(300px, 90%, 680px);
  font-family: Nuckle, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 16px;

  a:hover {
    text-decoration: underline;
  }
}

.selected__container {
  margin: auto;
  margin-top: 28px;
  width: 30%;

  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  a:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
}

img {
  mix-blend-mode: invert;
}
.tns-controls{
  margin-top: 8px;
  display: flex !important;
  justify-content: space-between !important;

  button{
    border-radius: 0;
    border: 1px solid var(--black);
    background:var(--white);
    font-family: Neue haas, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    padding: 4px 8px;
    color: var(--black);
     @media screen and (max-width: 768px) {
       font-size: 12px;
     }
  }

  &:focus{
    outline: none;
  }
}

.tns-liveregion{
  display: none;
}
</style>
