<template>
  <div
    class="image__container"
    :class="slice.primary.orientation ? 'right' : ''"
  >
    <div class="block__container">
      <div class="images__container">
        <prismic-image :field="slice.primary.image1" v-if="slice.primary.image1"/>
        <prismic-image :field="slice.primary.image2" v-if="slice.primary.image2"/>
      </div>
      <div class="dida__container" v-if="slice.items[0]">
        <div v-if="slice.items[0].ph_cred[0]">{{ slice.items[0].ph_cred[0].text }}</div>
        <div class="dida" v-if="slice.items[0].image_dida[0]">{{ slice.items[0].image_dida[0].text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoubleImage",
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>
<style lang="scss" scoped>
.image__container {
  margin: auto;
  margin-top: 200px;
  width: clamp(150px, 90%, 1440px);
  display: flex;
}

.block__container {
  width: 66.66%;
}
.images__container {
  display: flex;
  justify-content: space-between;
  img {
    width: 49.5%;
    height: auto;
  }
}

.dida__container {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;

  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;

  letter-spacing: 0.01em;
  text-transform: uppercase;
  div {
    max-width: 40%;
  }
    @media screen and (max-width:768px) {
    font-size: 10px;
  }
}

.right {
  justify-content: right;
}

.dida {
  text-align: right;
}
</style>
