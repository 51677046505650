<template>
  <div class="prj">
    <div class="header_prj">
      <a href="/"
        >VALENTINA <br />
        FURIAN</a
      >
      <div class="header__title">WORKS</div>
    </div>
    <work-list
      v-for="work in worksa"
      :key="work.data.title[0].text"
      :title="work.data.title[0].text"
      :intro="work.data.dida[0].text"
      :year="new Date(work.data.date_creation).getFullYear()"
      :id="work.uid"
    ></work-list>
  </div>
</template>

<script>
import workList from "../components/workList.vue";
export default {
  components: { workList },
  data() {
    return {
      worksa: [],
    };
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "work"),
        { orderings: "[my.work.date_creation desc]" }
      );
      //   console.log(response.results)
      response.results.forEach((el) => {
        // console.log(el)
        this.worksa.push(el);
      });
      // console.log(this.worksa);
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.prj {
  background: #000000;
  min-height: 100vh;
  margin-bottom: 200px;
}
a {
  text-decoration: none;
  color: white;
}

.header_prj {
  position: sticky;
  background: black;
  top: 0;
  padding-top: 20px;
  color: white;
  font-size: 30px;
  text-align: center;
  letter-spacing: 2px;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;
  border-bottom: 0.5px solid #ffffff;
  .header__title {
    margin-top: 54px;
    margin-bottom: 18px;
    font-family: Nukle, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
  }
}
</style>
