<template>
  <div class="press__container">
    <div class="press__title"><h3>SYNOPSIS:</h3></div>
    <div class="press__text">
      <prismic-rich-text :field="slice.primary.press" />
    </div>
  </div>
</template>

<script>
export default {
  name: "PressRelease",
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>

<style lang="scss" scoped>
.press__container {
  margin: auto;
  margin-top: 82px;
  width: clamp(150px, 90%, 1080px);
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 26px;
  /* identical to box height, or 130% */
  letter-spacing: 0.01em;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.press__title {
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  padding-right: 5%;
}
.press__text {
  font-size: 16px;
  line-height: 162%;
  padding-left: 5%;
  letter-spacing: 0.01em;
}
</style>
