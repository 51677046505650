<template>
  <div class="press__container">
    <div class="press__text">
      <prismic-rich-text :field="slice.primary.content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TextLeft",
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>

<style lang="scss" scoped>
.press__container {
  margin: auto;
  margin-top: 82px;
  width: clamp(150px, 90%, 1440px);
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 26px;
  /* identical to box height, or 130% */
  letter-spacing: 0.01em;
}
.press__title {
  font-weight: bold;
  font-size: 16px;
  text-align: right;
  padding-right: 5%;
}
.press__text {
  font-size: 16px;
  line-height: 162%;
  margin-left: 0;
  @media screen and (min-width: 768px) {
  width: 66.66%;
  }
  // padding-left: 5%;
  letter-spacing: 0.01em;
}
</style>
