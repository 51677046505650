<template>
<div class="allall__container">
<div class="all__container">
  <div class="singleImg__container">
    <prismic-image
      :field="slice.primary.image_full"
      v-if="slice.primary.image_full"
    />
    </div>
        <div class="dida__container" v-if="slice.items[0]">
      <div></div>
      <div class="dida" v-if="slice.items[0].image_dida[0]">
        {{ slice.items[0].image_dida[0].text }}
      </div>
      <div v-if="slice.items[0].ph_cred[0]">
        {{ slice.items[0].ph_cred[0].text }}
      </div>
    </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "ImageFullNew",
  data() {
    return {};
  },
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>

<style lang="scss" scoped>
.singleImg__container {
  margin: auto;
  margin-top: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  max-width: clamp(150px, 100%, 1440px);
  // height: 100vh;

  img {
    // width: 100%;
    margin: auto;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: clamp(500px, 100vh, 1440px);
    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
    }
    // 100vh
  }
}

.all__container{
    margin: auto;
    width: auto;
    // background: coral;
    max-width: 90%;
  display: inline-block;
  // width: clamp(300px, 90%, 1440px);
}

.dida__container {
  
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: flex-end;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;
  text-align: right;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}
.dida {
  text-align: center;
}

.allall__container{
  display: flex;
  justify-content: center;
}
</style>
