import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";
import VideoEmbed from "v-video-embed";
import vueVimeoPlayer from 'vue-vimeo-player';
import VueTinySlider from 'vue-tiny-slider';

const accessToken = ""; // Leave empty if your repo is public
const endpoint = "https://valentinafurian.prismic.io/api/v2"; // Use your repo name

Vue.config.productionTip = false;

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: {
    accessToken
  },
  linkResolver,
});
Vue.use(VideoEmbed);
Vue.use(vueVimeoPlayer);

new Vue({
  router,
  store,
  components: {
    'tiny-slider': VueTinySlider
  },
  render: (h) => h(App),
}).$mount("#app");