<template>
  <div class="text__container">
    <prismic-rich-text :field="slice.primary.title1" v-if="slice.primary.title1"/>
    <div class="text__intro">
      <prismic-rich-text :field="slice.primary.parag" v-if="slice.primary.parag"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextCenter",
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice)
  },
};
</script>

<style lang="scss">
.text__title {
  max-width: 80%;
  margin: auto;
  text-align: center;
  font-size: 100px;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-top: 150px;
  @media screen and (max-width:768px) {
    font-size: 10vw;
  }
}
.text__container {
margin-top: 200px;
}

.text__intro {
  
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 162%;
  /* or 162% */

  text-align: center;
  width: clamp(150px, 80%, 800px);
  margin: auto;
  margin-top: 68px;
  letter-spacing: 0.01em;

  margin-bottom: 120px;
  @media screen and (max-width:768px) {
    font-size: 14px;
  }
}
</style>
