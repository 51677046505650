import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Works from "../views/Prj.vue";
import Exhibitions from "../views/Exhib.vue";
import Test from "../views/Test.vue";
import SingleWork from "../views/SingleWork.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/works",
    name: "Works",
    component: Works,
  },
  {
    path: "/works/:id",
    name: "SingleWork",
    component: SingleWork,
  },
  {
    path: "/Calendar",
    name: "Calendar",
    component: Exhibitions,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
