<template>
  <div class="exhib">
    <div class="header">
      <a href="/"
        >VALENTINA <br />
        FURIAN</a
      >
    </div>
    <div class="img__container" id="img__cont">
      <!-- <img src="https://images.prismic.io/valentinafurian/8e04860d-bdd2-4903-b8c9-44d19d95ade0_2021_PT_locandina-WEB-e-MAIL+%281%29.jpeg?auto=compress,format" alt=""> -->
    </div>
    <div class="events__container">
      <div class="events__grid">
        <h2 class="events__title">CALENDAR</h2>
      </div>
      <div
        class="event__container events__grid"
        v-for="event in events"
        :key="event.name[0].text"
        v-on:mouseover.capture="imgViz(event.image.url)"
      >
        <a v-if="event.link.url" :href="event.link.url" target="blank"
          ><h3 class="event__title">{{ event.name[0].text }}</h3></a
        >
        <h3 v-else class="event__title">{{ event.name[0].text }}</h3>
        <div class="event__infoContainer">
          {{ new Date(event.start).getDate() }}.{{ addZero(event.start)
          }}<span v-if="event.end"
            >-{{ new Date(event.end).getDate() }}.{{ addZero(event.end) }}.{{
              new Date(event.end).getFullYear()
            }}</span
          ><span v-else>.{{ new Date(event.start).getFullYear() }}</span>
          <prismic-rich-text
            :field="event.info"
            :htmlSerializer="htmlSerializer"
          />
          <!-- <div class="event__info" v-html="$prismic.asHtml(event.info)" :htmlSerializer="htmlSerializer" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      events: [],
    };
  },
  methods: {
    imgViz(url) {
      var el = document.getElementById("img__cont");
      el.style.backgroundImage = `url(${url})`;
      //  el.style.background = "green";
    },
    htmlSerializer(type, element, content, children) {
      // If element is a list item,
      if (type === "paragraph") {
        // return some customized HTML.
        return `<p class="example-class" style="margin:0, font-family: sans">${children.join(
          ""
        )}</p>`;
      }
      /// Otherwise, return null.
      return null;
    },
    addZero(mydate) {
      var date = new Date(mydate);
      var string = ("0" + (date.getMonth() + 1)).slice(-2);
      return string;
    },
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "event"),
        { orderings: "[my.event.start desc]" }
      );
      //   console.log(response.results)
      response.results.forEach((el) => {
        // console.log(el)
        this.events.push(el.data);
      });
      // console.log(this.events);
    },
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.exhib {
  min-height: 100vh;
  background: black;
  a {
    text-decoration: none;
    color: white;
  }
}

.header {
  position: sticky;

  top: 0;
  padding-top: 20px;
  color: white;
  font-size: 30px;
  text-align: center;
  letter-spacing: 1px;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-weight: bold;

  .header__title {
    margin-top: 54px;
    margin-bottom: 18px;

    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
  }
}

.events__container {
  font-family: Nukle, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  grid-template-rows: auto;
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: 0;

  margin-top: 54px;
  padding-bottom: 54px;

  a:hover {
    color: #db3832;
  }
  @media screen and (max-width:768px) {
    width: 90%;
    margin: 54px auto;
  }
}

.events__title {
  grid-column-start: 2;
  font-family: Nukle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 144%;

  text-align: left;
  letter-spacing: 0.01em;
  margin-bottom: 64px;
  color: #ffffff;
}

.event__container {
  margin-bottom: 32px;
}

.event__title {
  text-align: right;
  grid-column-start: 1;
  margin: 0;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.01em;
}
.event__infoContainer {
  text-align: left;
  grid-column-start: 2;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 144%;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;
  padding-right: 20%;
}


.events__grid {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 20px;
}

.example-class {
  color: red !important;
}

.img__container {
  position: fixed;
  // background: red;
  margin-top: 76px;
  left: 5%;
  width: 40%;
  height: 70vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width:768px) {
    display: none;
  }
}
</style>
