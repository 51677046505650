<template>
  <div class="credits__block">
    <h2 class="credits__title">CREDITS</h2>
    <div class="credits__container">
      <div
        class="singleCredit__container"
        v-for="(item, i) in slice.items"
        :key="`slice-item-${i}`"
      >
        <div class="ruolo">{{ item.ruolo[0].text }}</div>
        <div class="nomi">
          <prismic-rich-text
            :field="item.nomi"
            :htmlSerializer="htmlSerializer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Credits",
  props: {
    slice: Object,
  },
  methods: {
    htmlSerializer(type, element, content, children) {
      // If element is a list item,
      if (type === "paragraph") {
        // return some customized HTML.
        return `<p class="nomi_para" style="margin:0">${children.join("")}</p>`;
      }
      /// Otherwise, return null.
      return null;
    },
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>

<style lang="scss" scoped>
.credits__block {
  margin-top: 200px;
}
.credits__title {
  font-family: Nuckle, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  text-align: center;
  letter-spacing: 0.01em;

  margin-bottom: 62px;
   @media screen and (max-width: 768px) {
     font-size: 16px;
     margin-bottom: 42px;
   }
}
.credits__container {
  width: clamp(150px, 90%, 1080px);
  margin: auto;
}
.singleCredit__container {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin-bottom: 14px;
  letter-spacing: 0.02em;
  @media screen and (max-width: 768px) {
     font-size: 14px;
   }
}
.ruolo {
  width: 50%;

  text-align: right;
  font-size: 16px;
  font-weight: bold;
     @media screen and (max-width: 768px) {
     font-size: 14px;
   }
}

.nomi {
  width: 50%;

  text-transform: uppercase;
}

.nomi_para {
  margin: 0 !important;
  padding: 0 !important;
}
</style>