<template>
  <div class="image__container">
    <div class="block__container">
      <div class="images__container">
        <prismic-image
          class="full"
          :field="slice.primary.image_full"
          :class="slice.primary.orientation ? 'right' : ''"
          v-if="slice.primary.image_full"
        />
        <prismic-image class="vert" :field="slice.primary.image_vertical" v-if="slice.primary.image_vertical"/>
      </div>
      <div class="dida__container" v-if="slice.items[0]">
        <div v-if="slice.items[0].ph_cred[0]">{{ slice.items[0].ph_cred[0].text }}</div>
        <div v-if="slice.items[0].image_dida[0]" class="dida">{{ slice.items[0].image_dida[0].text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageDoubleFull",
  props: {
    slice: Object,
  },
  mounted() {
    // console.log(this.slice);
  },
};
</script>
<style lang="scss" scoped>
.image__container {
  margin: auto;
  margin-top: 200px;
  width: clamp(150px, 90%, 1440px);
  display: flex;
}

.block__container {
  width: 100%;
}
.images__container {
  width: 100%;
  display: flex;
  height: auto;
}

.full {
  width: 62% !important;
  height: auto !important;
  margin-right: 1%;
  height: auto;
  // flex-grow: 1.6;
  // order: 2;
}
.vert {
  // width: 100% !important;
  width: 37% !important;
  height: auto;
  // flex-grow: 0.9;
}

.dida__container {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;

  font-family: Neue haas, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 144%;

  letter-spacing: 0.01em;
  text-transform: uppercase;
  div {
    max-width: 40%;
  }
    @media screen and (max-width:768px) {
    font-size: 10px;
  }
}

.right {
  order: 2;
  margin-right: 0;
  margin-left: 1%;
}

.dida {
  text-align: right;
}
</style>
